import React from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import './App.css'; // Ensure this file contains the necessary styles

// Data and table creation function for the new league table
function createData(name, played, points, highlight = false) {
    return { name, played, points, highlight };
}

const rows2022 = [
    createData('RWP', 10, 46),
    createData('Beaufort Advanced', 10, 45),
    createData('Frampton Cotterell', 10, 43, true), // Highlight row
    createData('Bristol Falcons', 10, 24),
    createData('Avon Jets', 10, 19),
];

// Component to render the old HTML data using dangerouslySetInnerHTML
const OldHtmlContent = () => {
    const oldHtml = `
<HTML xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<HEAD>
<TITLE>league tables</TITLE>
<meta name="description" content="Frampton Cotterell Badminton Club is a friendly club of moderate standard, playing in the lower divisions of both the Bristol and the Mid Gloucestershire Badminton Leagues.">
<meta name="keywords" content="badminton, club, league, fixtures, frampton cotterell, yate, king edmund school, division, shuttle, racket, sport.">
<style type="text/css">
.style2 {
	background-color: #FFFF00;
}
.style4 {
	background-color: #FFFF00;
	font-family: Arial, Helvetica, sans-serif;
	font-size: small;
}
.style5 {
	font-size: small;
}
.style6 {
	font-family: Arial, Helvetica, sans-serif;
	font-size: small;
}
.style12 {
	background-color: #FFFF00;
	text-align: center;
}
.style13 {
	text-align: center;
}
.style14 {
	font-family: Arial;
	font-size: medium;
}
.style15 {
	font-family: Arial;
	font-size: medium;
	text-align: center;
}
.style16 {
	background-color: #FFFF00;
	font-family: Arial;
	font-size: medium;
	text-align: center;
}
.style17 {
	background-color: #FFFF00;
	font-family: Arial;
	font-size: medium;
}
.style19 {
				text-align: center;
				font-family: Arial, Helvetica, sans-serif;
}
.style20 {
				font-family: Arial, Helvetica, sans-serif;
}
.style21 {
				font-weight: bold;
				text-align: center;
}
.style22 {
				font-family: Arial, Helvetica, sans-serif;
				font-weight: bold;
}
.style23 {
				background-color: #FFFF00;
				text-align: center;
				font-size: medium;
				font-family: Arial, Helvetica, sans-serif;
}
.style24 {
				text-align: center;
				font-family: Arial, Helvetica, sans-serif;
				font-size: medium;
}
.style25 {
				border-width: 1px;
				font-family: Arial;
				font-size: medium;
}
.style26 {
				border-width: 1px;
				font-family: Arial;
				font-size: medium;
				text-align: center;
}
.style27 {
				font-family: Arial, Helvetica, sans-serif;
				font-size: medium;
}
.style29 {
				font-family: Arial, Helvetica, sans-serif;
				font-size: medium;
				color: rgb(0, 0, 0);
				font-style: normal;
				font-variant: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
				text-align: left;
				text-indent: 0px;
				text-transform: none;
				white-space: normal;
				word-spacing: 0px;
				display: inline;
}
.style30 {
				border-width: 1px;
				font-family: Arial, Helvetica, sans-serif;
				font-size: medium;
				color: rgb(0, 0, 0);
				font-style: normal;
				font-variant: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
				text-align: left;
				text-indent: 0px;
				text-transform: none;
				white-space: normal;
				word-spacing: 0px;
				display: inline;
}
.style31 {
				font-family: Arial, Helvetica, sans-serif;
				font-size: medium;
				color: rgb(0, 0, 0);
				font-style: normal;
				font-variant: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
				text-align: left;
				text-indent: 0px;
				text-transform: none;
				white-space: normal;
				word-spacing: 0px;
				display: inline;
				background-color: #FFFF00;
}
.style32 {
				font-family: Arial;
}
</style>
<meta http-equiv="Content-Type" content="text/html; charset=">
</HEAD>

<BODY BGCOLOR="#FFFFFF" TEXT="#000000" background="shuttle-tr.gif">


<div align="left">
  <p align="left"><font face="Arial, Helvetica, sans-serif" size="2">These are
    the three league tables which are relevant to our teams, for the full league
    tables for Bristol and Mid Gloucestershire please refer to the
	<a href="http://www.avonba.co.uk/">Avon Badminton
    Association website</a> which is listed on our Contact page.</font><br>
    <b><font face="Arial, Helvetica, sans-serif" size="2">Last updated
	<!--webbot bot="Timestamp" S-Type="EDITED" S-Format="%d/%m/%Y" startspan -->10/05/2014<!--webbot bot="Timestamp" endspan i-checksum="12566" --></font></b></p>
</div>
<div align="left">
<div align="left">
<table width="100%" border="0" cellpadding="0" cellspacing="10" height="216">
  <tr>
    <td valign="top" style="height: 36px; " colspan="2" class="style19">
        <strong>2013-2014 Season</strong></td>
    </tr>
	<tr>
    <td valign="top" style="height: 181px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">
			Bristol League (Mens) Div 4B</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      		&nbsp;</td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
							Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
							Pts</font></b></div>
            </td>
          </tr>
			<tr>
            <td class="style30" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Beaufort 4</span></td>
            <td width="10%" class="style26">
              8</td>
            <td align="center" class="style25" style="width: 9%">
              39&nbsp;</td>
          </tr>
			<tr>
            <td class="style27" style="width: 45%">
			<span style="color: rgb(0, 0, 0); font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Keynsham</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              31</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Academy</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              23</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Broadlands</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              22</td>
          </tr>
			<tr>
            <td class="style31" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Frampton Cotterell</td>
            <td width="10%" class="style16">
              8</td>
            <td align="center" class="style17" style="width: 9%">
              5</td>
          </tr>
          </table>
    </td>
    <td valign="top" class="style13" style="height: 181px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League Mixed </span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Mixed Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
							Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
							Pts</font></b></div>
          </td>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style17">
			Frampton</td>
          <td width="11%" class="style23">
            10&nbsp;</td>
          <td width="11%" class="style16">
            7</td>
          <td width="10%" class="style16">
            57</td>
		</tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Raysfield</td>
          <td width="11%" class="style15">
            10</td>
          <td width="11%" class="style15">
            8</td>
          <td width="10%" class="style15">
            54</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			St Ursulas</td>
          <td width="11%" class="style24">
            10</td>
          <td width="11%" class="style15">
            4</td>
          <td width="10%" class="style15">
            49</td>
        </tr>
        <tr>
          <td width="68%" class="style25">
			RWP</td>
          <td width="11%" class="style26">
            10</td>
          <td width="11%" class="style26">
            6</td>
          <td width="10%" class="style26">
            48</td>
    	</tr>
		<tr>
      <strong>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%" class="style24">
            10</td>
          <td width="11%" class="style15">
            4</td>
          <td width="10%" class="style15">
            36</td>
		</tr>
		<tr>
      <strong>
          <td width="68%" class="style14" style="height: 16px">
			Radminton</td>
          <td width="11%" class="style15" style="height: 16px">
            10</td>
          <td width="11%" class="style15" style="height: 16px">
            1</td>
          <td width="10%" class="style15" style="height: 16px">
            26</td>
    	</tr>
        </table>
    	</tr>
  </tr>
	<tr>
    <td valign="top" style="height: 170px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mixed) Div 5B</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      &nbsp;</td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Academy</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              36</td>
          </tr>
			<tr>
            <td class="style30" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="color: rgb(0, 0, 0); font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			K</span><span style="color: rgb(0, 0, 0); font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">ingswood</span></td>
            <td width="10%" class="style26">
              8</td>
            <td align="center" class="style25" style="width: 9%">
              35</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="font-variant: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Bishop Road 3</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              28</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			<span style="color: rgb(0, 0, 0); font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: normal; orphans: auto; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); display: inline !important; float: none;" class="style32">
			Raysfield 2</span></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              11</td>
          </tr>
			<tr>
            <td class="style31" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Frampton Cotterell</td>
            <td width="10%" class="style16">
              8</td>
            <td align="center" class="style17" style="width: 9%">
              10</td>
          </tr>
			</table>
      </td>
  </tr>
  <tr>
    <td valign="top" style="height: 15px; " colspan="2" class="style19">
<div align="left">
      <hr width="75%" class="style13">
</div>
		</td>
    </tr>
  <tr>
    <td valign="top" style="height: 36px; " colspan="2" class="style19">
        <strong>2012-2013 Season</strong></td>
    </tr>
	<tr>
    <td valign="top" style="height: 181px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mens) Div 4B</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      		&nbsp;</td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
			<tr>
            <td class="style27" style="width: 45%">
			Keynsham Intermediates</td>
            <td width="10%" class="style15">
              6</td>
            <td align="center" class="style14" style="width: 9%">
              21</td>
          </tr>
			<tr>
            <td class="style30" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Chew Park 2</td>
            <td width="10%" class="style26">
              5</td>
            <td align="center" class="style25" style="width: 9%">
              20</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Broadlands</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              20</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			University of Bristol</td>
            <td width="10%" class="style15">
              4</td>
            <td align="center" class="style14" style="width: 9%">
              19</td>
          </tr>
          <tr>
            <td class="style31" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Frampton Cotterell</td>
            <td width="10%" class="style16">
              5</td>
            <td align="center" class="style17" style="width: 9%">
              4</td>
          </tr>
          </table>
    </td>
    <td valign="top" class="style13" style="height: 181px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League Mixed </span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Mixed
			Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Severnvale</td>
          <td width="11%" class="style15">
            12</td>
          <td width="11%" class="style15">
            11</td>
          <td width="10%" class="style15">
            89</td>
        </tr>
		<tr>
      <strong>
          <td width="68%" class="style14">
			St. Lukes B</td>
          <td width="11%" class="style15">
            12</td>
          <td width="11%" class="style15">
            8</td>
          <td width="10%" class="style15">
            73</td>
        </tr>
		<strong>
        <tr>
          <td width="68%" class="style17">
			Frampton</td>
          <td width="11%" class="style23">
            12</td>
          <td width="11%" class="style16">
            8</td>
          <td width="10%" class="style16">
            65</td>
		</tr>
        <tr>
          <td width="68%" class="style25">
			RWP</td>
          <td width="11%" class="style26">
            12</td>
          <td width="11%" class="style26">
            5</td>
          <td width="10%" class="style26">
            57</td>
    	</tr>
        <tr>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%" class="style24">
            12</td>
          <td width="11%" class="style15">
            7</td>
          <td width="10%" class="style15">
            52</td>
		</tr>
		<tr>
          <td width="68%" class="style14">
			Radminton</td>
          <td width="11%" class="style15">
            12</td>
          <td width="11%" class="style15">
            2</td>
          <td width="10%" class="style15">
            25</td>
    	</tr>
		<tr>
          <td width="68%" class="style14">
			Roxburgh Park</td>
          <td width="11%" class="style24">
            12</td>
          <td width="11%" class="style15">
            1</td>
          <td width="10%" class="style15">
            16</td>
        </tr>
		</table>
    	</tr>
  </tr>
	<tr>
    <td valign="top" style="height: 170px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mixed) Div 5B</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      <strong>
      		<b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 6A</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
			<tr>
            <td class="style30" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Imperial</td>
            <td width="10%" class="style26">
              8</td>
            <td align="center" class="style25" style="width: 9%">
              35</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Bishop Road 2</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              29</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Academy</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              28</td>
          </tr>
			<tr>
            <td class="style29" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Link</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              19</td>
          </tr>
			<tr>
            <td class="style31" style="width: 45%; orphans: 2; widows: 2; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; float: none;">
			Frampton Cotterell</td>
            <td width="10%" class="style16">
              8</td>
            <td align="center" class="style17" style="width: 9%">
              9</td>
          </tr>
			</table>
      </td>
  </tr>
  <tr>
    <td valign="top" style="height: 15px; " colspan="2" class="style19">
<div align="left">
      <hr width="75%" class="style13">
</div>
				</td>
    </tr>
  <tr>
    <td colspan="2" valign="top" class="style19">
		<strong>2011-2012 Season</strong></td>
  </tr>
  <tr>
    <td valign="top" style="height: 195px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mens)</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      		<b>
      <strong>
      		<font face="Arial, Helvetica, sans-serif" size="3">
			Division 5</font></strong><font face="Arial, Helvetica, sans-serif" size="3">A</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Beaufort 4</td>
            <td width="10%" class="style15">
              12</td>
            <td align="center" class="style14" style="width: 9%">
              59</td>
          </tr>
          <tr>
            <td class="style17" style="width: 45%">
			<font size="2" class="style14">
			Frampton Cotterell</font></td>
            <td width="10%" class="style16">
              12</td>
            <td align="center" class="style17" style="width: 9%">
              54</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Nailsea &amp; Backwell</font></td>
            <td width="10%" class="style15">
              12&nbsp;</td>
            <td align="center" class="style14" style="width: 9%">
              36</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Portishead 2</td>
            <td width="10%" class="style15">
              12</td>
            <td align="center" class="style14" style="width: 9%">
              36</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Bristol Medics 1</font></td>
            <td width="10%" class="style15">
              12</td>
            <td align="center" class="style14" style="width: 9%">
              33</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Bishop Road 2</td>
            <td width="10%" class="style15">
              12</td>
            <td align="center" class="style14" style="width: 9%">
              31</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Bristol Medics 2</font></td>
            <td width="10%" class="style15">
              12</td>
            <td align="center" class="style14" style="width: 9%">
              3</td>
          </tr>
          </table>
    </td>
    <td valign="top" class="style13" style="height: 195px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League Mixed </span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Tetbury</td>
          <td width="11%">
            <div align="center" class="style14">				10</div>
          </td>
          <td width="11%" class="style15">
            9</td>
          <td width="10%" class="style15">
            74</td>
		</tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			St Ursula�s</td>
          <td width="11%" class="style15">
            10</td>
          <td width="11%" class="style15">
            7</td>
          <td width="10%" class="style15">
            52</td>
    	</tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%" class="style24">
            10</td>
          <td width="11%" class="style15">
            5</td>
          <td width="10%" class="style15">
            42</td>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell </font></td>
          <td width="11%" class="style16">
            10</td>
          <td width="11%" class="style16">
            4</td>
          <td width="10%" class="style16">
            39</td>
    	</tr>
		<tr>
          <td width="68%" class="style14">
			Roxburgh</td>
          <td width="11%" class="style15">
            10&nbsp;</td>
          <td width="11%" class="style15">
            2</td>
          <td width="10%" class="style15">
            32</td>
        </tr>
		<tr>
          <td width="68%" class="style14">
			RWP</td>
          <td width="11%" class="style15">
            10</td>
          <td width="11%" class="style15">
            3</td>
          <td width="10%" class="style15">
            31</td>
        </tr>
		</table>
    	</tr>
  <tr>
    <td valign="top" style="height: 170px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mixed) Div 6a</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      <strong>
      		<b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 6A</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Kingswood </td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              40</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			Link</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              36</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Raysfield 2</font></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              18</td>
          </tr>
			<tr>
            <td class="style17" style="width: 45%">
			<font size="2" class="style14">
			Frampton Cotterell</font></td>
            <td width="10%" class="style16">
              8</td>
            <td align="center" class="style17" style="width: 9%">
              17</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			Avon Jets 2</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              9</td>
          </tr>
			</table>
      </td>
  </tr>
  <tr>
    <td colspan="2" valign="top" class="style19">
<div align="left">
      <hr width="75%">
</div>
				</td>
  </tr>
  </table>
</div>
</div>
<div align="left">
<table width="100%" border="0" cellpadding="0" cellspacing="10" height="216">
  <tr>
    <td colspan="2" valign="top" class="style19">
      <strong>2010-2011 Season</strong></td>
  </tr>
  <tr>
    <td valign="top" style="height: 195px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mens)</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      <strong>
      		<b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 5B</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Keynsham Intermediates</td>
            <td width="10%" class="style15">
              10</td>
            <td align="center" class="style14" style="width: 9%">
              54</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			RWP 2nd</td>
            <td width="10%" class="style15">
              10</td>
            <td align="center" class="style14" style="width: 9%">
              43</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Redwood Lodge</td>
            <td width="10%" class="style15">
              10</td>
            <td align="center" class="style14" style="width: 9%">
              33</td>
          </tr>
          <tr>
            <td class="style17" style="width: 45%">
			<font size="2" class="style14">
			Frampton Cotterell</font></td>
            <td width="10%" class="style16">
              10</td>
            <td align="center" class="style17" style="width: 9%">
              23</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Nailsea &amp; Backwell</font></td>
            <td width="10%" class="style15">
              10</td>
            <td align="center" class="style14" style="width: 9%">
              19</td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Bristol Medics 2</font></td>
            <td width="10%" class="style15">
              10</td>
            <td align="center" class="style14" style="width: 9%">
              8</td>
          </tr>
          </table>
    </td>
    <td valign="top" class="style13" style="height: 195px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League Mixed A</span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Glos+Stars</td>
          <td width="11%">
            <div align="center" class="style14">				8</div>
          </td>
          <td width="11%" class="style15">
            7</td>
          <td width="10%" class="style15">
            55</td>
		</tr>
        <tr>
          <td width="68%" class="style14">
			Severnvale</td>
          <td width="11%" class="style15">
            8</td>
          <td width="11%" class="style15">
            6</td>
          <td width="10%" class="style15">
            47</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%" class="style24">
            8</td>
          <td width="11%" class="style15">
            4</td>
          <td width="10%" class="style15">
            28</td>
        </tr>
		<tr>
          <td width="68%" class="style14">
			St
			Ursula�s</td>
          <td width="11%" class="style15">
            8</td>
          <td width="11%" class="style15">
            1</td>
          <td width="10%" class="style15">
            27</td>
    	</tr>
        <tr>
          <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell A</font></td>
          <td width="11%" class="style16">
            8</td>
          <td width="11%" class="style16">
            2</td>
          <td width="10%" class="style16">
            23</td>
    	</tr>
        </table>
    	</tr>
  <tr>
    <td valign="top" style="height: 170px; width: 434px;">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mixed)</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td style="width: 45%">
      <strong>
      		<b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 6A</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td style="width: 9%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td class="style14" style="width: 45%">
			Academy</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              30</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			Exiles</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              33</td>
          </tr>
			<tr>
            <td class="style17" style="width: 45%">
			<font size="2" class="style14">
			Frampton Cotterell</font></td>
            <td width="10%" class="style16">
              8</td>
            <td align="center" class="style17" style="width: 9%">
              27</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			Raysfield 2nd</td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              20</td>
          </tr>
			<tr>
            <td class="style14" style="width: 45%">
			<font size="2" class="style14">
			Bristol Medics 2</font></td>
            <td width="10%" class="style15">
              8</td>
            <td align="center" class="style14" style="width: 9%">
              10</td>
          </tr>
          </table>
      </td>
    <td valign="top" class="style13" style="height: 170px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League B</span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 3 </font></b></td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
          </strong>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Tetbury</td>
          <td width="10%" align="center" class="style14">
            6</td>
          <td width="11%" class="style14" align="center">
            6</td>
          <td width="11%" align="center" class="style14">
            43</td>
          </strong>
		</tr>
        <tr>
						<strong>
          <td width="68%" class="style14">
			RWP</td>
          <td width="10%" align="center" class="style14">
            6</td>
          <td width="11%" class="style14" align="center">
            4</td>
          <td width="11%" align="center" class="style14">
            29</td>
          </strong>
		</tr>
        <tr>
          <td width="68%" class="style14">
			Berkley</td>
      <strong>
          <td width="10%" class="style15">
            6</td>
          <td width="11%" class="style15">
            2</td>
          <td width="11%" class="style15">
            25</td>
          </strong>
		</tr>
		<tr>
      <strong>
          <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell B</font></td>
          <td width="10%" class="style23">
            6</td>
          <td width="11%" class="style16">
            0</td>
          <td width="11%" class="style16">
            11</td>
          </strong>
          </tr>
		<strong>
          </strong>
		</table>
    </td>
  </tr>
  <tr>
    <td colspan="2" valign="top">
      &nbsp;</td>
  </tr>
  </table>
</div>
<div align="left">
      <hr width="75%">
</div>
<table width="100%" border="0" cellpadding="0" cellspacing="10" height="216">
  <tr>
    <td colspan="2" valign="top" class="style19">
      <strong>2009-2010 Season</strong></td>
  </tr>
  <tr>
    <td valign="top" style="height: 195px">
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td colspan="3" style="width: 78%" class="style21"><font face="Arial, Helvetica, sans-serif">Bristol League
		(Mens)</font></td>
          </tr>
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 5A</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Clevedon Feathers</td>
            <td width="10%" class="style15">
              11</td>
            <td width="10%" align="center" class="style14">
              51</td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Imperial</td>
            <td width="10%" class="style15">
              12</td>
            <td width="10%" align="center" class="style14">
              41</td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			St Ursulas 2</td>
            <td width="10%" class="style15">
              8</td>
            <td width="10%" align="center" class="style14">
              39</td>
          </tr>
          <tr>
            <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell</font></td>
            <td width="10%" class="style16">
              11</td>
            <td width="10%" align="center" class="style17">
              36</td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Exiles</td>
            <td width="10%" class="style15">
              11</td>
            <td width="10%" align="center" class="style14">
              32</td>
          </tr>
          <tr class="style6">
            <td width="68%" class="style14">
			<font size="2" class="style14">
			Raysfield 2</font></td>
            <td width="10%" class="style15">
              12</td>
            <td width="10%" align="center" class="style14">
              24</td>
          </tr>
			<tr>
            <td width="68%" class="style14">
			<font size="2" class="style14">
			Bristol Medics 2</font></td>
            <td width="10%" class="style15">
              11</td>
            <td width="10%" align="center" class="style14">
              5</td>
          </tr>
          </table>
    </td>
    <td valign="top" class="style13" style="height: 195px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League Mixed A</span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
						<strong>
          <td width="68%" class="style14">
			St Lukes B</td>
          <td width="11%" class="style13">
            <div align="center" class="style15">				6</div>
          </td>
          				</strong>
          <td width="11%" class="style15">
            5</td>
          <td width="10%" class="style15">
            44</td>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell A</font></td>
          <td width="11%" class="style16">
            8</td>
          <td width="11%" class="style16">
            3</td>
          <td width="10%" class="style16">
            42</td>
    	</strong>
        </tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%" class="style15">
            7</td>
          <td width="11%" class="style15">
            6</td>
    	</strong>
      <strong>
          <td width="10%" class="style15">
            39</td>
    	</strong>
    	</tr>
        <tr>
        <strong>
          <td width="68%" class="style14">
			St
			Ursula�s</td>
          <td width="11%">
            <div align="center" class="style14">				7</div>
          </td>
          <td width="11%" class="style15">
            3</td>
          <td width="10%" class="style15">
            19</td>
    	</strong>
		</tr>
        <tr>
      <strong>
          <td width="68%" class="style14">
			RWP</td>
          <td width="11%" class="style15">
            8</td>
          <td width="11%" class="style15">
            1</td>
          <td width="10%" class="style15">
            18</td>
    	</strong>
		</tr>
        <strong>
        </table>
    	</strong>
  </tr>
  <tr>
    <td valign="top" style="height: 170px">
      </td>
    <td valign="top" class="style13" style="height: 170px">
      <strong>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td colspan="4" class="style13">
      <strong><span class="style20">Mid Glos League B</span></strong></td>
        </tr>
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 3 </font></b></td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center" class="style22">Won</div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
          </strong>
        <tr>
      <strong>
          <td width="68%" class="style14">
			Glos + Stars</td>
          <td width="10%">
            <div align="center" class="style14">12</div>
          </td>
          <td width="11%" class="style15">
            12</td>
          <td width="11%" class="style15">
            101</td>
          </strong>
          </tr>
        <tr>
          <td width="68%" class="style14">
			<font size="2" class="style14">Tetbury</font></td>
      <strong>
          <td width="10%" class="style15">
            12</td>
          <td width="11%" class="style15">
            8</td>
          <td width="11%" class="style15">
            62</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Berkeley</td>
          <td width="10%" align="center" class="style14">
            12</td>
          <td width="11%" class="style14" align="center">
            2</td>
          <td width="11%" align="center" class="style14">
            29</td>
        </tr>
        <tr>
          <td width="68%" class="style17">
			<font size="2" class="style14">Frampton
            Cotterell B</font></td>
          <td width="10%" align="center" class="style17">
            12</td>
          <td width="11%" class="style17" align="center">
            2</td>
          <td width="11%" align="center" class="style17">
            24&nbsp;</td>
        </tr>
          </strong>
		</table>
    </td>
  </tr>
  <tr>
    <td colspan="2" valign="top">
      <hr width="75%">
    </td>
  </tr>
  <tr>
    <td colspan="2" valign="top">
      <p align="center"><b><font face="Arial, Helvetica, sans-serif">2008 -
        2009 Season</font></b></td>
  </tr>
  <tr>
    <td valign="top" rowspan="2">
      <p align="center"><font face="Arial, Helvetica, sans-serif"><b> Bristol League
		(Mens)</b></font></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 5b</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pld</font></b></div>
            </td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">
				Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Medics</td>
            <td width="10%" class="style14" align="center">
              6</td>
            <td width="10%" align="center" class="style14">
              15</td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Avon
			Jets B</td>
            <td width="10%" class="style14" align="center">
              6</td>
            <td width="10%" align="center" class="style14">
              13</td>
          </tr>
          <tr class="style6">
            <td width="68%" class="style14" bgcolor="#FFFF00">
			<font size="2" class="style14">
			<span class="style2">Frampton Cotterell</span></font></td>
            <td width="10%" class="style14" bgcolor="#FFFF00" align="center">
              6</td>
            <td width="10%" bgcolor="#FFFF00" align="center" class="style14">
              13</td>
          </tr>
          <tr>
            <td width="68%" class="style14">
			Exile</td>
            <td width="10%" class="style14" align="center">
              6</td>
            <td width="10%" align="center" class="style14">
              7</td>
          </tr>
			<tr>
            <td width="68%" class="style14">
			Fromeside</td>
            <td width="10%" class="style14" align="center">
              0</td>
            <td width="10%" align="center" class="style14">
              0</td>
          </tr>
          </table>
    </td>
    <td height="10" valign="top">
      <p align="center"><font face="Arial, Helvetica, sans-serif"><b> Mid Glos
        League Mixed A</b></font></p>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 2</font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Gms</font></b></div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Raysfield</td>
          <td width="11%" class="style13">
            <div align="center" class="style15">8</div>
          </td>
          <td width="11%" class="style15">
            103</td>
          <td width="10%" class="style15">
            16</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Severn Vale B</td>
          <td width="11%">
            <div align="center" class="style14">				8</div>
          </td>
          <td width="11%" class="style15">
            89</td>
          <td width="10%" class="style15">
            12</td>
        </tr>
		<tr>
          <td width="68%" class="style14">
			Imperial</td>
          <td width="11%">
            <div align="center" class="style14">				8</div>
          </td>
          <td width="11%" class="style15">
            79</td>
          <td width="10%" class="style15">
            7</td>
        </tr>
		<tr>
          <td width="68%" class="style17">
			<font size="2" class="style14">
			Frampton Cotterell A</font></td>
          <td width="11%" class="style12">
            <div align="center" class="style15">				8</div>
          </td>
          <td width="11%" class="style16">
            56</td>
          <td width="10%" class="style16">
            4</td>
        </tr>
		<tr>
          <td width="68%" class="style14">
			St
			Ursula�s</td>
          <td width="11%">
            <div align="center" class="style14">				8</div>
          </td>
          <td width="11%" class="style15">
            33</td>
          <td width="10%">
            <div align="center" class="style14">				1</div>
          </td>
        </tr>
        </table>
    </td>
  </tr>
  <tr>
    <td valign="top" style="height: 33px">
      <p align="center"><font face="Arial, Helvetica, sans-serif"><b>Mid Glos
        League Mixed B</b></font></p>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">
			Division 3 </font></b></td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			R.W.P.</td>
          <td width="10%">
            <div align="center" class="style14">				10</div>
          </td>
          <td width="11%" class="style15">
            124</td>
          <td width="11%" class="style15">
            17</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Berkeley</td>
          <td width="10%">
            <div align="center" class="style14">10</div>
          </td>
          <td width="11%">
            <div align="center" class="style14">117</div>
          </td>
          <td width="11%">
            <div align="center" class="style14">14</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Yate</td>
          <td width="10%" align="center" class="style14">
            10</td>
          <td width="11%" class="style14" align="center">
            125</td>
          <td width="11%" align="center" class="style14">
            13</td>
        </tr>
        <tr>
          <td width="68%" class="style14">
			Tetbury</td>
          <td width="10%" align="center" class="style14">
            10</td>
          <td width="11%" class="style14" align="center">
            88</td>
          <td width="11%" align="center" class="style14">
            12</td>
        </tr>
		<tr>
          <td width="68%" class="style17">
			<font size="2" class="style14">Frampton
            Cotterell B</font></td>
          <td width="10%" class="style2">
            <div align="center" class="style14">				10</div>
          </td>
          <td width="11%" class="style16">
            49</td>
          <td width="11%" class="style2">
            <div align="center" class="style14">				2</div>
          </td>
        </tr>
		<tr>
          <td width="68%" class="style14">
			Avon
			Jets</td>
          <td width="10%" align="center" class="style14">
            10</td>
          <td width="11%" class="style14" align="center">
            37</td>
          <td width="11%" align="center" class="style14">
            2</td>
        </tr>
        </table>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <br>
      <hr width="75%">
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <div align="center"><b><font face="Arial, Helvetica, sans-serif">2007 -
        2008 Season</font></b></div>
    </td>
  </tr>
  <tr>
    <td height="20" valign="top">
      <div align="center">
        <p><font face="Arial, Helvetica, sans-serif"><b> Bristol League Mixed</b></font></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              6b</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
            </td>
            <td width="12%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></div>
            </td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Kingsdown
              2 </font></td>
            <td width="10%" class="style6">
              <div align="center" class="style6">				8</div>
            </td>
            <td width="12%">
              <div align="center" class="style6">				42</div>
            </td>
            <td width="10%">
              <div align="center" class="style6">				14</div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Yate
              2 </font></td>
            <td width="10%" class="style6">
              <div align="center" class="style6">8</div>
            </td>
            <td width="12%">
              <div align="center" class="style6">32</div>
            </td>
            <td width="10%">
              <div align="center" class="style6">11</div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style4">
			<font face="Arial, Helvetica, sans-serif" size="2" class="style5">
			<span class="style2">Frampton
              Cotterell</span></font></td>
            <td width="10%" class="style4">
              <div align="center" class="style6">				8</div>
            </td>
            <td width="12%" class="style2">
              <div align="center" class="style6">24</div>
            </td>
            <td width="10%" class="style2">
              <div align="center" class="style6">10</div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">UWE
              2 </font></td>
            <td width="10%" class="style6">
              <div align="center" class="style6">8</div>
            </td>
            <td width="12%">
              <div align="center" class="style6">14</div>
            </td>
            <td width="10%">
              <div align="center" class="style6">5</div>
            </td>
          </tr>
          <tr>
            <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Highbury</font></td>
            <td width="10%" class="style6">
              <div align="center" class="style6">8</div>
            </td>
            <td width="12%">
              <div align="center" class="style6">6</div>
            </td>
            <td width="10%">
              <div align="center" class="style5">
				<font face="Arial, Helvetica, sans-serif">0</font></div>
            </td>
          </tr>
          </table>
      </div>
    </td>
    <td height="20" valign="top">
      <div align="center"></div>
      <p align="center"><font face="Arial, Helvetica, sans-serif"><b> Mid Glos
        League Mixed A</b></font></p>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
            3 </font></b></td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Gms</font></b></div>
          </td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style4">
			<font face="Arial, Helvetica, sans-serif" size="2" class="style5">Frampton
            Cotterell A</font></td>
          <td width="11%" class="style2">
            <div align="center" class="style6">				8</div>
          </td>
          <td width="11%" class="style2">
            <p align="center" class="style6">			113</td>
          <td width="10%" class="style2">
            <p align="center" class="style6">			16</td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Yate</font></td>
          <td width="11%">
            <div align="center" class="style6">				8</div>
          </td>
          <td width="11%" class="style6">
            <div align="center" class="style6">				88</div>
          </td>
          <td width="10%">
            <div align="center" class="style6">				12</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Tetbury</font></td>
          <td width="11%">
            <div align="center" class="style6">				8</div>
          </td>
          <td width="11%" class="style6">
            <div align="center" class="style6">74</div>
          </td>
          <td width="10%">
            <div align="center" class="style6">8</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Pucklechurch</font></td>
          <td width="11%">
            <div align="center" class="style6">8</div>
          </td>
          <td width="11%">
            <div align="center" class="style6">44</div>
          </td>
          <td width="10%">
            <div align="center" class="style6">2</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Bishop
            Road </font></td>
          <td width="11%">
            <div align="center" class="style6">				8</div>
          </td>
          <td width="11%">
            <div align="center" class="style6">				41</div>
          </td>
          <td width="10%">
            <div align="center" class="style6">2</div>
          </td>
        </tr>
      </table>
      <p align="center"><font face="Arial, Helvetica, sans-serif"><b>Mid Glos
        League Mixed B</b></font></p>
      <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
        <tr bgcolor="#99CCFF">
          <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
            4 </font></b></td>
          <td width="10%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></div>
          </td>
          <td width="11%">
            <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style4">
			<font face="Arial, Helvetica, sans-serif" size="2" class="style5">Frampton
            Cotterell B</font></td>
          <td width="10%" class="style2">
            <div align="center" class="style6">				6</div>
          </td>
          <td width="11%" class="style2">
            <div align="center" class="style6">				67</div>
          </td>
          <td width="11%" class="style2">
            <div align="center" class="style6">				12</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Berkeley</font></td>
          <td width="10%">
            <div align="center" class="style6">				6</div>
          </td>
          <td width="11%" class="style6">
            <div align="center" class="style6">				63</div>
          </td>
          <td width="11%">
            <div align="center" class="style6">8</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Avon
            Jets </font></td>
          <td width="10%">
            <div align="center" class="style6">6</div>
          </td>
          <td width="11%">
            <div align="center" class="style6">43</div>
          </td>
          <td width="11%">
            <div align="center" class="style6">2</div>
          </td>
        </tr>
        <tr>
          <td width="68%" class="style6">
			<font face="Arial, Helvetica, sans-serif">Kingswood</font></td>
          <td width="10%">
            <div align="center" class="style6">				6</div>
          </td>
          <td width="11%" class="style6">
            <div align="center" class="style6">				43</div>
          </td>
          <td width="11%">
            <div align="center" class="style5">
				<font face="Arial, Helvetica, sans-serif">2</font></div>
          </td>
        </tr>
        </table>
      <p>&nbsp;</p>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <hr width="75%">
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <div align="center"><b><font face="Arial, Helvetica, sans-serif">2006 -
        2007 Season</font></b></div>
    </td>
  </tr>
  <tr>
    <td height="396" valign="top">
      <div align="center">
        <p><font face="Arial, Helvetica, sans-serif"><b>Bristol League Mixed</b></font></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              6</font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
            </td>
            <td width="12%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></div>
            </td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Severn
              Vale 4</font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">65</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">23</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Broadlands
              2 </font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">51</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">18</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">UWE</font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">50</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">18</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
              Cotterell</font></td>
            <td width="10%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="12%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">29</font></div>
            </td>
            <td width="10%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">10</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Broadlands
              3 </font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">28</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">7</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Next
              Generation</font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">11</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">17</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">4</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%" height="23"><font face="Arial, Helvetica, sans-serif" size="2">Avon
              Jets </font></td>
            <td width="10%" height="23">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">11</font></div>
            </td>
            <td width="12%" height="23">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="10%" height="23">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">0</font></div>
            </td>
          </tr>
        </table>
        <p>&nbsp;</p>
      </div>
    </td>
    <td height="396" valign="top">
      <div align="center">
        <p><b><font face="Arial, Helvetica, sans-serif">Mid Gloucestershire League
          Mixed A</font></b></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              3 </font></b></td>
            <td width="11%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
            </td>
            <td width="11%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Gms</font></b></div>
            </td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">St
              Ursulas </font></td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">108</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">16</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Tetbury</font></td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">67</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">10</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Bishop
              Road </font></td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">72</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
              Cotterell A</font></td>
            <td width="11%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
            <td width="11%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">75</font></div>
            </td>
            <td width="10%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Berkeley</font></td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">38</font></div>
            </td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">0</font></div>
            </td>
          </tr>
        </table>
        <p>&nbsp;</p>
        <p><b><font face="Arial, Helvetica, sans-serif">Mid Gloucestershire League
          Mixed B</font></b></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC">
          <tr bgcolor="#99CCFF">
            <td width="68%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              4 </font></b></td>
            <td width="10%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></div>
            </td>
            <td width="11%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></div>
            </td>
            <td width="11%">
              <div align="center"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Yate</font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">82</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">10</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
              Cotterell B</font></td>
            <td width="10%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">64</font></div>
            </td>
            <td width="11%" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Kingswood</font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">44</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
          </tr>
          <tr>
            <td width="68%"><font face="Arial, Helvetica, sans-serif" size="2">Avon
              Jets </font></td>
            <td width="10%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">26</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">0</font></div>
            </td>
          </tr>
        </table>
        <p>&nbsp;</p>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <div align="center">
        <hr width="75%">
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="20" valign="top">
      <div align="center"><b><font face="Arial, Helvetica, sans-serif">2005 -
        2006 Season</font></b></div>
    </td>
  </tr>
  <tr>
    <td valign="top" height="275">
      <div align="center">
        <p><font face="Arial, Helvetica, sans-serif"><b>Bristol League Mixed</b></font></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC" height="210">
          <tr bgcolor="#99CCFF">
            <td width="58%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              5B </font></b></td>
            <td width="14%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Pld</font></b></font></div>
            </td>
            <td width="13%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Sets</font></b></font></div>
            </td>
            <td width="15%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Pts</font></b></font></div>
            </td>
          </tr>
          <tr>
            <td width="58%"><font face="Arial, Helvetica, sans-serif" size="2">Redwood
              Lodge </font></td>
            <td width="14%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">61</font></div>
            </td>
            <td width="15%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">22</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%" height="26"><font face="Arial, Helvetica, sans-serif" size="2">Portishead</font></td>
            <td width="14%" height="26">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%" height="26">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">55</font></div>
            </td>
            <td width="15%" height="26">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">20</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%" height="31"><font face="Arial, Helvetica, sans-serif" size="2">St
              Ursulas 2</font></td>
            <td width="14%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">38</font></div>
            </td>
            <td width="15%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">14</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%" height="31"><font face="Arial, Helvetica, sans-serif" size="2">Broadlands</font></td>
            <td width="14%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">33</font></div>
            </td>
            <td width="15%" height="31">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%" height="29"><font face="Arial, Helvetica, sans-serif" size="2">Nailsea
              &amp; Yatton Optimists 2</font></td>
            <td width="14%" height="29">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%" height="29">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">27</font></div>
            </td>
            <td width="15%" height="29">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">10</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%"><font face="Arial, Helvetica, sans-serif" size="2">Pill</font></td>
            <td width="14%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">33</font></div>
            </td>
            <td width="15%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">8</font></div>
            </td>
          </tr>
          <tr>
            <td width="58%" height="27" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
              Cotterell</font></td>
            <td width="14%" height="27" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">12</font></div>
            </td>
            <td width="13%" height="27" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">5</font></div>
            </td>
            <td width="15%" height="27" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">0</font></div>
            </td>
          </tr>
        </table>
      </div>
    </td>
    <td height="275" valign="top">
      <div align="center">
        <p><font face="Arial, Helvetica, sans-serif"><b>Mid Gloucestershire League
          Mixed</b></font></p>
        <table width="100%" border="1" cellpadding="2" cellspacing="0" bordercolor="#CCCCCC" height="153">
          <tr bgcolor="#99CCFF">
            <td width="65%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
              4 </font></b></td>
            <td width="12%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Pld</font></b></font></div>
            </td>
            <td width="11%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Gms</font></b></font></div>
            </td>
            <td width="12%">
              <div align="center"><font size="3"><b><font face="Arial, Helvetica, sans-serif">Pts</font></b></font></div>
            </td>
          </tr>
          <tr>
            <td width="65%" height="28" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
              Cotterell</font></td>
            <td width="12%" height="28" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%" height="28" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">66</font></div>
            </td>
            <td width="12%" height="28" bgcolor="#FFFF00">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">9</font></div>
            </td>
          </tr>
          <tr>
            <td width="65%" height="28"><font face="Arial, Helvetica, sans-serif" size="2">Tetbury</font></td>
            <td width="12%" height="28">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%" height="28">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">54</font></div>
            </td>
            <td width="12%" height="28">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
          </tr>
          <tr>
            <td width="65%" height="30"><font face="Arial, Helvetica, sans-serif" size="2">Berkeley</font></td>
            <td width="12%" height="30">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%" height="30">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">51</font></div>
            </td>
            <td width="12%" height="30">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
          </tr>
          <tr>
            <td width="65%"><font face="Arial, Helvetica, sans-serif" size="2">Yate</font></td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></div>
            </td>
            <td width="11%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">45</font></div>
            </td>
            <td width="12%">
              <div align="center"><font face="Arial, Helvetica, sans-serif" size="2">3</font></div>
            </td>
          </tr>
        </table>
        <p align="left">&nbsp;</p>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="13" valign="top">
      <hr width="75%">
    </td>
  </tr>
  <tr>
    <td colspan="2" height="13" valign="top">
      <div align="center">
        <h4><b><font face="Arial, Helvetica, sans-serif">2004 - 2005 Season</font></b></h4>
      </div>
    </td>
  </tr>
  <tr>
    <td width="51%" height="204" valign="top">
      <h4 align="center"><b><font face="Arial, Helvetica, sans-serif">Bristol
        League Mixed</font></b></h4>
      <table width="100%" border="1" bordercolor="#CCCCCC" cellpadding="2" cellspacing="0" height="158">
        <tr bgcolor="#99CCFF">
          <td width="65%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
            6a </font></b></td>
          <td width="12%"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></td>
          <td width="12%"><b><font face="Arial, Helvetica, sans-serif" size="3">Sets</font></b></td>
          <td width="11%"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></td>
        </tr>
        <tr bordercolor="#CCCCCC">
          <td width="65%"><font face="Arial, Helvetica, sans-serif" size="2">Beaufort
            3 </font></td>
          <td width="12%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">39</font></td>
          <td width="11%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">13</font></td>
        </tr>
        <tr bordercolor="#CCCCCC">
          <td width="65%"><font face="Arial, Helvetica, sans-serif" size="2">Portishead</font></td>
          <td width="12%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">31</font></td>
          <td width="11%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">10</font></td>
        </tr>
        <tr bordercolor="#CCCCCC">
          <td width="65%" height="25"><font face="Arial, Helvetica, sans-serif" size="2">St.
            Ursulas 2</font></td>
          <td width="12%" align="center" height="25">8</td>
          <td width="12%" align="center" height="25"><font face="Arial, Helvetica, sans-serif" size="2">23</font></td>
          <td width="11%" align="center" height="25"><font face="Arial, Helvetica, sans-serif" size="2">9</font></td>
        </tr>
        <tr bordercolor="#CCCCCC">
          <td width="65%"><font face="Arial, Helvetica, sans-serif" size="2">Morton
            2 </font></td>
          <td width="12%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">22</font></td>
          <td width="11%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">7</font></td>
        </tr>
        <tr bordercolor="#CCCCCC">
          <td width="65%" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
            Cotterell</font></td>
          <td width="12%" align="center" bgcolor="#FFFF00">8</td>
          <td width="12%" align="center" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">3</font></td>
          <td width="11%" align="center" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">1</font></td>
        </tr>
      </table>
    </td>
    <td width="49%" height="204" valign="top">
      <h4 align="center"><font face="Arial, Helvetica, sans-serif"><b>Mid Gloucestershire
        League Mixed</b></font></h4>
      <table width="100%" border="1" height="157" bordercolor="#CCCCCC" cellpadding="2" cellspacing="0">
        <tr bgcolor="#99CCFF">
          <td width="69%"><b><font face="Arial, Helvetica, sans-serif" size="3">Division
            4 </font></b></td>
          <td width="10%"><b><font face="Arial, Helvetica, sans-serif" size="3">Pld</font></b></td>
          <td width="12%"><b><font face="Arial, Helvetica, sans-serif" size="3">Gms</font></b></td>
          <td width="9%"><b><font face="Arial, Helvetica, sans-serif" size="3">Pts</font></b></td>
        </tr>
        <tr>
          <td width="69%" height="25"><font face="Arial, Helvetica, sans-serif" size="2">St.
            Ursulas </font></td>
          <td width="10%" align="center" height="25">8</td>
          <td width="12%" align="center" height="25"><font face="Arial, Helvetica, sans-serif" size="2">118</font></td>
          <td width="9%" align="center" height="25"><font face="Arial, Helvetica, sans-serif" size="2">16</font></td>
        </tr>
        <tr>
          <td width="69%" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">Frampton
            Cotterell </font></td>
          <td width="10%" align="center" bgcolor="#FFFF00">8</td>
          <td width="12%" align="center" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">77</font></td>
          <td width="9%" align="center" bgcolor="#FFFF00"><font face="Arial, Helvetica, sans-serif" size="2">10</font></td>
        </tr>
        <tr>
          <td width="69%"><font face="Arial, Helvetica, sans-serif" size="2">Tetbury</font></td>
          <td width="10%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">72</font></td>
          <td width="9%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">6</font></td>
        </tr>
        <tr>
          <td width="69%"><font face="Arial, Helvetica, sans-serif" size="2">Yate</font></td>
          <td width="10%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">48</font></td>
          <td width="9%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">4</font></td>
        </tr>
        <tr>
          <td width="69%"><font face="Arial, Helvetica, sans-serif" size="2">Berkeley</font></td>
          <td width="10%" align="center">8</td>
          <td width="12%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">45</font></td>
          <td width="9%" align="center"><font face="Arial, Helvetica, sans-serif" size="2">4</font></td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td colspan="2" height="2" valign="top">
      <hr width="75%">
    </td>
  </tr>
</table>
<div align="left">
  <p>&nbsp;</p>
</div>


</BODY>

</HTML>
    `;

    return <div dangerouslySetInnerHTML={{ __html: oldHtml }} />;
};

function LeagueTables() {
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                League Table Results
            </Typography>
            <Typography paragraph>
                These are the historic end-of-season league tables which are relevant to our club and teams for the past 12 years.
            </Typography>
            <Typography paragraph>
                For the current Bristol league tables in which we have teams, please refer to the <a href="https://www.avonba.org/leagues" target="_blank" rel="noopener noreferrer">Avon Badminton Association</a> website.
            </Typography>
            <Typography paragraph>Last updated 04/10/2024</Typography>

            {/* New League Table */}
            <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Team</TableCell>
                            <TableCell align="right">Played</TableCell>
                            <TableCell align="right">Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows2022.map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: row.highlight ? '#f0f0f0' : 'none' }}
                                >
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.played}</TableCell>
                                <TableCell align="right">{row.points}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Old HTML Content */}
            <OldHtmlContent />
        </Container>
    );
}

export default LeagueTables;
