import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import './App.css'; // Assuming this contains the necessary styles

import crossbowImage from './images/crossbow.jpg';
import brockeridgeImage from './images/brockeridge.jpg';
import kes1Image from './images/kes1.jpg';

function History() {
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Frampton Cotterell Badminton Club - A Short History
            </Typography>
            <Typography paragraph>
                Frampton Cotterell Badminton Club was formed between 1940 - 1943. It was started
                by people from St Peters Church, Frampton Cotterell and held at the Church Hall,
                School Road (now the Crossbow Community Centre). The hall could only accommodate
                a singles court, inside tram lines, with a low beam that could be hit with our rackets.
                Club night was Monday.
            </Typography>
            <Typography paragraph>
                The next venue was St Michaels Rooms, Winterbourne in the 1970s. At last a full
                size court (still with beams but not such a problem) and we were able to join the league.
            </Typography>
            <Typography paragraph>
                Then it was to Brockeridge School, Frampton Cotterell for 2 seasons. Finally, we settled
                at our present home of King Edmund School, Yate (Tuesdays) although up until the late 90s
                we also had a club night at Downend School (Thursdays).
                In 2009 King Edmund School became Yate Academy.
            </Typography>
            <Typography paragraph>
                (Acknowledgements: Thanks to Keith Maggs [joined FCBC in 1954] for the above information).
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 16, textAlign: 'center' }}>
                        <img src={crossbowImage} alt="Crossbow Community Centre" style={{ width: '100%', height: 'auto' }} />
                        <Typography>Crossbow Community Centre</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 16, textAlign: 'center' }}>
                        <img src={brockeridgeImage} alt="Brockeridge School" style={{ width: '100%', height: 'auto' }} />
                        <Typography>Brockeridge School</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 16, textAlign: 'center' }}>
                        <img src={kes1Image} alt="King Edmund School" style={{ width: '100%', height: 'auto' }} />
                        <Typography>King Edmund School</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default History;
