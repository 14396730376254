import React, { useEffect } from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';
import './App.css'; // Assuming this contains the necessary styles

function Committee() {
    useEffect(() => {
        document.title = document.title + " - Comittee"; // Set this dynamically as needed
    }, []); // Adjust dependencies based on when you need to update the title

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Committee Members 2023-24
                    </Typography>
                    <Paper style={{ padding: '20px', margin: '10px 0' }}>
                        <Typography variant="h6">Chair - John</Typography>
                        <Typography variant="h6">Treasurer - Rod</Typography>
                        <Typography variant="h6">Secretary - Loulou</Typography>
                        <Typography variant="h6">Team Selectors - John, Anna & Lucy</Typography>
                        <Typography variant="h6">Fixtures Secretary - Alec</Typography>
                        <Typography variant="h6">Web Site - Gareth</Typography>
                        <Typography variant="h6">General Members - Ellie</Typography>
                        <Typography variant="h6">Shuttle Management - Rod</Typography>
                    </Paper>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <img src="./images/G0131967.gif" alt="Committee Scene" height="297" width="448" />*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
}

export default Committee;
