import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Tabs, Tab, Container, Grid, Card, CardContent, CardMedia, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css';
import racquets from './images/racquets.jpg';
import sportsHall from './images/FCBChall2.JPG';

import About from './About';
import History from './History';
import Fixtures from './Fixtures';
import LeagueTables from './LeagueTables';
import News from './News';
import Committee from './Committee';
import Contact from './Contact';

function App() {
    const [value, setValue] = React.useState(0);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <List>
            {['Home', 'About', 'History', 'Fixtures', 'League Tables', 'News', 'Committee', 'Contact'].map((text, index) => (
                <ListItem button key={text} component={Link} to={text === 'Home' ? '/' : `/${text.toLowerCase().replace(' ', '_')}`} onClick={handleDrawerToggle}>
                    <ListItemText primary={text} />
                </ListItem>
            ))}
        </List>
    );

    return (
        <Router>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ marginBottom: 4 }}>
                    <Toolbar>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            {isMobile ? 'FCBC' : 'Frampton Cotterell Badminton Club'}
                        </Typography>
                        {isMobile ? (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <Tabs value={value} onChange={handleChange} aria-label="navigation tabs" sx={{
                                '.MuiTab-root': {
                                    padding: '6px 12px',
                                    fontSize: '0.85rem',
                                    letterSpacing: '0.1rem',
                                    textTransform: 'none'
                                },
                                '.Mui-selected': {
                                    fontWeight: 'bold',
                                }
                            }}>
                                <Tab label="Home" component={Link} to="/" />
                                <Tab label="About Us" component={Link} to="/about" />
                                <Tab label="History" component={Link} to="/history" />
                                <Tab label="Fixtures" component={Link} to="/fixtures" />
                                <Tab label="League Tables" component={Link} to="/league_tables" />
                                <Tab label="News" component={Link} to="/news" />
                                <Tab label="Committee" component={Link} to="/committee" />
                                <Tab label="Contact" component={Link} to="/contact" />
                            </Tabs>
                        )}
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor="right"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                >
                    {drawer}
                </Drawer>

                <Routes>
                    <Route path="/about" element={<About />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/fixtures" element={<Fixtures />} />
                    <Route path="/league_tables" element={<LeagueTables />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/committee" element={<Committee />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/" element={
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Card raised>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={racquets}
                                        alt="Racquets"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Frampton Cotterell Badminton Club
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            We are a friendly club of moderate standard, playing in the Bristol Badminton Leagues. Please use the links above to look round our site and find out more.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Card raised>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Like to join us?
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            We are always happy to welcome new players to our club. Details of membership can be found on the <Link to="/about">About us</Link> page.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Card raised>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={sportsHall}
                                        alt="Sports hall"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Where to find us
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            We play in the sports hall at: Yate Academy, Sundridge Park, Yate, Tuesday nights 8.00 - 10.00 pm.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    } />
                </Routes>
            </Container>
        </Router>
    );
}

export default App;
