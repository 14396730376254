import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import './App.css'; // Assuming this file contains the necessary styles

function News() {
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                FCBC News
            </Typography>
            <Paper style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="body1" paragraph>
                    <strong>Last update: 04/10/24</strong>
                </Typography>
                <Typography variant="body1" paragraph>
                    The club has entered three teams into the Bristol League for 2024/25. One Mixed team completing in Division 5, One Open team completing in Division 7, and a Womens team completing in Division 3. Good luck to all the teams. Anyone wishing to play in league matches should let the Selectors know.
                </Typography>
            </Paper>
        </Container>
    );
}

export default News;
