import React from 'react';
import { Container, Typography, Link, Grid, Paper } from '@mui/material';
import './App.css'; // Make sure your CSS handles the layout correctly or use inline styles/MUI system

function Contact() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Contact Us at Frampton Cotterell Badminton Club
                    </Typography>
                    <Typography variant="body1">
                        You can email us at <Link href="mailto:fcbcuk@gmail.com">fcbcuk@gmail.com</Link>
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Some Useful Links
                    </Typography>
                    <Paper style={{ padding: 16 }}>
                        <ul>
                            <li>
                                {/*<img src="./images/shuttlesmall.gif" alt="shuttle" style={{ verticalAlign: 'middle' }} />*/}
                                <Link href="http://www.avonba.co.uk" target="_blank" rel="noopener noreferrer">
                                    Avon Badminton Association
                                </Link> - for information about local clubs, league results, county badminton, and other news.
                            </li>
                            <li>
                                {/*<img src="./images/shuttlesmall.gif" alt="shuttle" style={{ verticalAlign: 'middle' }} />*/}
                                <Link href="http://www.badmintonengland.co.uk" target="_blank" rel="noopener noreferrer">
                                    Badminton England
                                </Link> - for information about clubs countrywide, national and international badminton news, shop and details of special events.
                            </li>
                        </ul>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Contact;
