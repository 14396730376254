import React from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent, Box } from '@mui/material';
import './App.css';
import shuttleIcon from './images/shuttlesmall.gif';
import hall1 from './images/FCBChall1.JPG';
import hall2 from './images/FCBChall2.JPG';
import hall3 from './images/FCBChall3.JPG';
import hall4 from './images/FCBChall4.JPG';

function AboutUs() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" gutterBottom component="div">
                        <Box display="flex" alignItems="center" gap={1}>
                            <img src={shuttleIcon} alt="Shuttle icon" style={{ width: 24, height: 24 }} />
                            Club Night
                        </Box>
                    </Typography>
                    <Typography paragraph>
                        Tuesday evenings 8.00 - 10.00pm all year round at Yate Academy (Ex. King Edmunds), Yate.
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        <Box display="flex" alignItems="center" gap={1}>
                            <img src={shuttleIcon} alt="Shuttle icon" style={{ width: 24, height: 24 }} />
                            League Teams
                        </Box>
                    </Typography>
                    <Typography paragraph>
                        For the 2024/25 season the club has enterred  One Mixed team,  One Open team and a Womens team in the Bristol Badminton League. For further details please refer the 'News Page'.
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        <Box display="flex" alignItems="center" gap={1}>
                            <img src={shuttleIcon} alt="Shuttle icon" style={{ width: 24, height: 24 }} />
                            Courts
                        </Box>
                    </Typography>
                    <Typography paragraph>
                        Throughout the year, there are 4 courts available on club nights, with home matches played on a Wednesday night.
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        <Box display="flex" alignItems="center" gap={1}>
                            <img src={shuttleIcon} alt="Shuttle icon" style={{ width: 24, height: 24 }} />
                            Membership
                        </Box>
                    </Typography>
                    <Typography paragraph>
                        The current membership fees are: Adults �155 (club fees) + (Badminton England & Avon Badminton Fees).
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        <Box display="flex" alignItems="center" gap={1}>
                            <img src={shuttleIcon} alt="Shuttle icon" style={{ width: 24, height: 24 }} />
                            New Members
                        </Box>
                    </Typography>
                    <Typography paragraph>
                        We are always happy to welcome new members. If the club's standard is too high for the player they may be better off at another club where they will be able to keep up with play.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" gutterBottom component="div">Gallery</Typography>
                    <Grid container spacing={2}>
                        {[hall1, hall2, hall3, hall4].map((image, index) => (
                            <Grid item xs={12} sm={6} md={12} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        image={image}
                                        alt={`Hall ${index + 1}`}
                                        style={{ width: '100%', height: 'auto', aspectRatio: '4/3', objectFit: 'cover' }}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AboutUs;
